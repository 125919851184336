<template>
  <a-card>
    <p style="color: #666666;">抖音自动接入到SaaS系统的客资，会按照如下配置自动为主播设置客服</p>
    <div style="margin-top: 20px; display: flex; align-items: center; overflow-x: scroll;"
      v-for="(item, index) in form">
      <span style="margin-right: 10px; flex-shrink: 0;">主播(客资归属人)</span>
      <a-select show-search :filter-option="filterOption" v-model:value="item.liveBroadcast" placeholder="请选择"
        allow-clear style="width: 100px;flex-shrink: 0;">
        <a-select-option v-for="option in list" :key="option.id" :name="option.name" :disabled="option.disabled">{{
        option.name }}
        </a-select-option>
      </a-select>
      <span style="margin: 0 10px; flex-shrink: 0;">负责客服</span>
      <a-select show-search :filter-option="filterOption" mode="multiple" v-model:value="item.staffIds"
        placeholder="请选择" allow-clear style="width: 100px;flex-shrink: 0;">
        <a-select-option v-for="option in list" :key="option.id" :name="option.name" :disabled="option.disabled">{{
        option.name }}
        </a-select-option>
      </a-select>
      <span style="margin: 0 10px; flex-shrink: 0;">客户来源(广告流)</span>
      <a-tree-select class="page-btm" show-search :filter-option="filterOption" v-model:value="item.customerSourceAd"
        style="width: 160px" allow-clear :tree-data="soureList" tree-default-expand-all placeholder="全部">
      </a-tree-select>
      <span style="margin: 0 10px; flex-shrink: 0;">客户来源(自然流)</span>
      <a-tree-select class="page-btm" show-search :filter-option="filterOption" v-model:value="item.customerSourceNature"
        style="width: 160px" allow-clear :tree-data="soureList" tree-default-expand-all placeholder="全部">
      </a-tree-select>
      <DeleteOutlined @click="deleteAnchor(index)"
        style="color: #1677ff; font-size: 16px; margin-left: 10px; cursor: pointer;flex-shrink: 0;" />
    </div>
    <a-button @click="addAnchor" type="primary" ghost style="margin-top: 20px;">+添加主播</a-button>
    <div style="margin-top: 40px;">
      <a-button type="primary" @click="save">保存</a-button>
    </div>
  </a-card>
</template>
<script>
import { get, post, postRequest } from "@/utils/http";
export default {
  name: 'CrmIndex',
  data() {
    return {
      form: [
        {
          liveBroadcast: null,
          staffIds: [],
          customerSourceAd: null,
          customerSourceNature: null
        }
      ],
      list: [],
      soureList: []
    }
  },
  mounted() {
    this.getList();
    this.getSoureList();
  },
  methods: {
    async getList() {
      try {
        let res = await get("/agency/institutionsCustomer/setAttributablePerson/get");
        if (res.code == '200') {
          this.list = res.employeesList.map(item => ({
            name: item.name,
            id: String(item.id)
          }));
          let st = setTimeout(()=>{
            this.getDetail();
            clearTimeout(st)
          }, 200)
        }
      } catch (error) {
        this.$message.error("请求失败")
      }
    },

    async getSoureList() {
      try {
        let res = await get("/option/config/getByOptionName?optionName=客户来源");
        if (res.code == '200') {
          res.data.map(i => {
              i.label = i.optionKey;
              i.value = i.optionValue;
              if (i.childrenList != '' && i.childrenList != null) {
                i.selectable = false;
                let arr = i.childrenList.split(',')
                i.children = arr.map(it => ({
                  label: it,
                  value: it,
                }))
              }
            })
          this.soureList = res.data;
        }
      } catch (error) {
        this.$message.error("请求失败")
      }
    },

    async getDetail() {
      try {
        let res = await get("/staff/config/list");
        if (res.code == '200') {
          this.form = res.data.map(item => ({
            liveBroadcast: item.liveBroadcast,
            staffIds: item.staffIds.split(','),
            customerSourceAd: item.customerSourceAd,
            customerSourceNature: item.customerSourceNature,
          }))
        }
      } catch (error) {
        this.$message.error("请求失败")
      }
    },

    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    deleteAnchor(index) {
      this.form.splice(index, 1)
    },

    addAnchor() {
      this.form.push({
        liveBroadcast: null,
        staffIds: [],
        customerSourceAd: null,
        customerSourceNature: null
      })
    },

    async save() {
      let list = this.form.map(item => ({
        liveBroadcast: item.liveBroadcast,
        staffIds: item.staffIds.join(','),
        customerSourceAd: item.customerSourceAd,
        customerSourceNature: item.customerSourceNature,
      }))
      try {
        let res = await postRequest("/staff/config/save", list);
        if (res.code === '200') {
          this.$message.success("保存成功");
          this.getDetail();
        }
      } catch (error) {
        this.$message.error("请求失败")
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
</style>